import querystring from 'querystring';
import {
    keysToCamelFromSnake,
    keysToSnakeFromCamel,
} from '../utils/caseconverters';

const API_URL = process.env.WAGTAIL_API_URL;
const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_WAGTAIL_API_URL;

interface CustomResponse {
    body: string;
    body64: string;
    contentType: string;
}

interface Redirect {
    destination: string;
    isPermanent: boolean;
}

interface RequestResponse {
    componentName?: string;
    componentProps?: any;
    redirect?: Redirect;
    customResponse?: CustomResponse,
    destination?: string;
    isPermanent?: boolean;
}

interface RequestOptions {
    headers?: Record<string, string>,
}

export async function getPage(path: string, params?: object, options?: RequestOptions) {
    params = params || {};
    params = {
        htmlPath: path,
        ...params,
    };

    return await getRequest(`${API_URL}/v1/page_by_path/`, params, options);
}

export async function getPasswordProtectedPage(
    restrictionId: number,
    pageId: number,
    params?: object,
    options?: RequestOptions,
) {
    params = params || {};
    return await postRequest(
        `${NEXT_PUBLIC_API_URL}/v1/password_protected_page/${restrictionId}/${pageId}/`,
        params,
        options
    );
}

export async function getAllPages() {
    return await getRequest(
        `${API_URL}/v1/page_relative_urls/`,
    );
}

export async function getPagePreview(contentType: string, token: string, params?: object, options?: RequestOptions) {
    params = params || {};
    params = {
        contentType,
        token,
        ...params,
    };

    return await getRequest(`${API_URL}/v1/page_preview/`, params, options);
}

export async function getPublicViewData(slug: string, params?: object, options?: RequestOptions) {
    return await getRequest(
        `${NEXT_PUBLIC_API_URL}/v1/external_view_data/${slug}/`,
        params,
        options
    );
}

export async function getViewData(slug: string, params?: object, options?: RequestOptions) {
    return await getRequest(
        `${API_URL}/v1/external_view_data/${slug}`,
        params,
        options
    );
}

export async function getRedirect(path: string, params?: object, options?: object) {
    params = params || {};
    params = {
        htmlPath: path,
        ...params,
    };

    return await getRequest(`${API_URL}/v1/redirect_by_path/`, params, options);
}

class RequestError extends Error {
    response: any

    constructor(message?: string, response?: any) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);

        this.response = response;
    }
}

export async function getRequest(url:string, params?: object, options?: RequestOptions): Promise<RequestResponse>{
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const queryString = querystring.stringify(params as querystring.ParsedUrlQueryInput);
    const res = await fetch(`${url}?${queryString}`, { headers });

    if (res.status < 200 || res.status >= 300) {
        const error = new RequestError(res.statusText, res);
        throw error;
    }

    const json = await res.json();
    return keysToCamelFromSnake(json);
}


export async function postRequest(url: string, params: object, options: RequestOptions): Promise<RequestResponse> {
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers,
    });

    if (res.status < 200 || res.status >= 300) {
        const error = new RequestError(res.statusText, res);
        throw error;
    }

    const json = await res.json();
    return keysToCamelFromSnake(json);
}
